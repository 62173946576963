<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=TCS%20Viale%20Villa%20Comunale%n.15%torre%20del%20greco%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>TCS Training Consulting Services
                    </h2>
                   <p>
                        Grazie alle proprie competenze ed alle esperienze maturate, TCS propone un’ampia gamma di servizi, articolati in Linee di Servizi che sono allo stesso tempo focalizzate su aree di intervento specifiche e sinergiche tra loro.
                   </p>
                   <p>
                        <b>CONSULENZA</b>
                        <br>
                        Servizi di Consulenza Direzionale, Organizzativa e Tecnologica IT, nelle aree Strategy & Governance, Service & Process Improvement e Technology Management. Offrono soluzioni concrete, basate su best practice riconosciute su proprie metodologie consolidate e sulle esperienze maturate, adeguandole alle specificità del Cliente nel rispetto di obiettivi di massima qualità, contenimento dei costi e tempestività di implementazione.
                  </p>
                  <p>
                    <b>PRODOTTI</b>
                    <br>
                    Prodotti software, contenuti E-Learning, Manuali rivolti alla didattica con forte contenuto innovativo, progettati e sviluppati in proprio. Supportano e completano i servizi di consulenza nelle diverse aree di competenza, offrendo soluzioni che consentono al Cliente di ottenere risultati rispondenti alle proprie esigenze con costi inferiori e tempi contenuti.
                </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Qual'è il tuo obiettivo professionale?</h2>
                    <p>
                        <b>FORMAZIONE</b>
                        <br>
                        Formazione su Soft Skills, Best Practice e Tecnologie IT, condotta sia presso la nostra sede di Torre del Greco, che presso le sedi dei nostri partner sul territorio nazionale che presso le sedi dei clienti. Basata su un ampio Catalogo di Corsi, la formazione può essere personalizzata in base a obiettivi specifici, alla realtà in cui operano le persone ed alle loro esperienze.
                    </p>
                    <p>
                        <b>FORMAZIONE SICUREZZA SUL LAVORO</b>
                        <br>
                        Lavoriamo con le aziende, organizzando tutte le tipologie di attività formative che riguardano gli obblighi previsti dal d.l.L.81/08 in materia di sicurezza sui luoghi di lavoro.
                        Completano l’offerta la preparazione alle certificazioni e l’effettuazione di sessioni di certificazione su best practice, metodologie, standard e strumenti di ampia diffusione.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Viale Villa Comunale n°15<br>80059 Torre del Greco (Na) </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:08118718100">(+39) 081-18718100</a></p>
                            <p>e-mail: <a href="mailto:pargol@trainingcs.it">pargol@trainingcs.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
